import React from "react";
import "./ClearAnswer.scss";
import { ArrowRight } from "../../../Icons";
import { Accordion } from "react-bootstrap";
import { Answer } from "../../../types/types";

const ClearAnswer: React.FC = () => {
  const allAnswer: Answer[] = [
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor mi sed dui suscipit, eu vehicula urna elementum. Nam molestie maximus pharetra. Vivamus augue velit, ",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor mi sed dui suscipit, eu vehicula urna elementum. Nam molestie maximus pharetra. Vivamus augue velit, ",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor mi sed dui suscipit, eu vehicula urna elementum. Nam molestie maximus pharetra. Vivamus augue velit, ",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor mi sed dui suscipit, eu vehicula urna elementum. Nam molestie maximus pharetra. Vivamus augue velit, ",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor mi sed dui suscipit, eu vehicula urna elementum. Nam molestie maximus pharetra. Vivamus augue velit, ",
    },
  ];
  return (
    <section id="clear-answer">
      <div className="container">
        <div className="top-contents">
          <h1 className="section-title">
            Clear Answers, Smooth Sailing, Find your FAQ fix here{" "}
            <img src="/assets/img/umbrella.png" alt="" />
          </h1>
          <button className="connect-btn">
            Let’s Connect{" "}
            <div className="right-arrow">
              <ArrowRight />
            </div>
          </button>
        </div>

        <div className="accordion">
          <Accordion defaultActiveKey={"0"}>
            {allAnswer.map((it, i) => (
              <Accordion.Item key={i} eventKey={`${i}`}>
                <Accordion.Header>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  tempor mi sed dui suscipit, eu vehicula urna elementum. Nam
                  molestie maximus pharetra. Vivamus augue velit, 
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default ClearAnswer;
