import React from "react";
import "./Hero.scss";
import Header from "../../global/header/Header";
import SearchBar from "../SearchBar/SearchBar";

const Hero: React.FC = () => {
  return (
    <header id="hero">
      <div className="container">
        <div className=" head-contents">
          <Header />
        </div>
        <button className="outline-btn">We've Got Your Wings Covered</button>
        <h1>Your Dream Trip Starts here, Compare & Book your Flight</h1>
        <SearchBar />
        <div>
          <div className="company-img-group">
            <img className="img1" src="/assets/img/company/img1.svg" alt="" />
            <img className="img2" src="/assets/img/company/img2.svg" alt="" />
            <img className="img3" src="/assets/img/company/img3.svg" alt="" />
            <img className="img4" src="/assets/img/company/img4.svg" alt="" />
            <img className="img5" src="/assets/img/company/img5.svg" alt="" />
          </div>

          <p>We are comparing these company's</p>
        </div>
      </div>
    </header>
  );
};

export default Hero;
