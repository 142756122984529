import {FilterSearch} from "../types/types";

export default class FilterUtil {
    public static getDefaultFilters(): FilterSearch[] {
        return [
            FilterUtil.getCountryFilter("Griekenland", "GR"),
            FilterUtil.getCountryFilter("Turkije", "TR"),
            FilterUtil.getCountryFilter("Spanje", "ES"),
            FilterUtil.getCountryFilter("Italië", "IT"),
            FilterUtil.getCountryFilter("Portugal", "PT"),
            FilterUtil.getCountryFilter("Kroatië", "HR"),
        ];
    }

    public static removeDuplicates(filters: FilterSearch[]): FilterSearch[] {
        return filters.filter(
            (filter, index, self) => {
                return self.findIndex(f => f.value === filter.value && f.filterType === filter.filterType) === index
            });
    }

    private static getCountryFilter(name: string, code: string): FilterSearch {
        return {
            filterCategory: "accommodation",
            filterType: "country",
            displayValue: name,
            value: code
        }
    }
}