export const getFormattedDate = (givenDate: Date | undefined): string => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = givenDate?.getDate();
  const monthIndex = givenDate?.getMonth();
  const year = givenDate?.getFullYear();

  // Format the date
  const formattedDate = `${day} ${monthNames[monthIndex]} , ${year}`;
  return formattedDate;
};
