import React from "react";
import "./Immerse.scss";
import { ArrowUpRight } from "../../../Icons";

export default function Immerse() {
  return (
    <section id="immerse-area">
      <div className="container">
        <div className="top-contents">
          <h1 className="section-title ">
            Immerse Yourself in Charming Towns & Vibrant Cities{" "}
            <img src="/assets/img/map.png" alt="" />{" "}
          </h1>

          <div className="btn-groups">
            {[
              "Amsterdam",
              "Keukenhof Gardens",
              "Kinderdijk Windmills",
              "Giethoorn",
              "Maastricht",
              "Delft",
              "Zaanse Schans",
              "The Hague",
            ].map((it, i) => (
              <button className="outline-btn" key={i}>
                {it}
              </button>
            ))}
          </div>
        </div>

        <div className="immerse-card-contents">
          {[
            "/assets/img/immerse/img1.webp",
            "/assets/img/immerse/img2.webp",
            "/assets/img/immerse/img3.webp",
            "/assets/img/immerse/img4.webp",
          ].map((it, i) => (
            <div className="item" key={i}>
              <img className="img-fluid" src={it} alt="" />
              <h3>Trip to Amsterdam</h3>
              <p>4,678 available trips</p>
              <a href="/">
                Checkout Deals & Compare <ArrowUpRight />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
