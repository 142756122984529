import React, {useEffect, useState} from "react";
import "./SearchBar.scss";
import {Link, useSearchParams} from "react-router-dom";
import SelectLocation from "../../common/SelectLocation/SelectLocation";
import AddGuest from "../../common/AddGuest/AddGuest";
import EnterDate from "../../common/EnterDate/EnterDate";
import SelectDestination from "../../common/SelectDestination/SelectDestination";
import {Filter, FilterSearch} from "../../../types/types";
import {filterService} from "../../../services/FilterService";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {FilterCategory} from "../../../types/filter/FilterCategory";
import FilterUtil from "../../../utils/FilterUtil";
import UrlUtil from "../../../utils/UrlUtil";

interface SearchBarProps {
  handleShow: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  handleShow,
}) => {

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [vacationTypes, setVacationTypes] = useState([]);
  const [filters, setFilters] = useState<Filter[]>([]);

  const [currentType, setCurrentType] = useState<string | undefined>(undefined);
  const [currentDestination, setCurrentDestination] = useState<string | undefined>(undefined);
  const [currentDuration, setCurrentDuration] = useState<string | undefined>(undefined);
  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
  const participantsAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    filterService.getFilters(selectedFilters, searchParams.get("filter") ?? "").then(filters => {
      if (filters.length === 0) return;
      setFilters(filters);

      const countries: Filter = filters.find(filter => filter.filterType === FilterCategory.COUNTRY);
      const regions: Filter = filters.find(filter => filter.filterType === FilterCategory.REGION);
      const cities: Filter = filters.find(filter => filter.filterType === FilterCategory.CITY);
      const types: Filter = filters.find(filter => filter.filterType === FilterCategory.VACATION_TYPE && filter.filterCategory === "vacation");

      setCountries(countries.values);
      setRegions(regions.values);
      setCities(cities.values);
      setVacationTypes(types.values);
    });
  }, [searchParams, selectedFilters]);

  useEffect(() => {
    const filters = FilterUtil.removeDuplicates([...selectedFilters, ...UrlUtil.parseUrlParamsToFilters(searchParams.get("filter") ?? "")]);
    let currentType: string | undefined = filters.find(f => f.filterType.toUpperCase() === "TYPE" && f.filterCategory.toUpperCase() === "VACATION")?.displayValue;
    let currentDestination: string | undefined = filters.find(f => f.filterType.toUpperCase() === "COUNTRY")?.displayValue;
    let currentDuration: string | undefined = filters.find(f => f.filterType.toUpperCase() === "DURATION")?.displayValue;

    setCurrentType(currentType);
    setCurrentDestination(currentDestination);
    setCurrentDuration(currentDuration);
    }, [searchParams, selectedFilters]);

  return (
    <div id="seach-search-bar">
      <div className="select-items-wrapper">
        <div className="select-item ">
          <h6>Vakantietype</h6>
          <SelectLocation
            showing="Selecteer vakantie type"
            vacationTypes={vacationTypes}
          />
        </div>
        <div className="border-right" />
        <div className="select-item t">
          <h6>Bestemming</h6>
          <SelectDestination
            showing="Selecteer bestemming"
            countries={countries}
            cities={cities}
            regions={regions}
          />
        </div>
        <div className="border-right" />
        <div className="select-item ">
          <h6>Wanneer</h6>
          {/* <h5>Enter Date</h5> */}
          <EnterDate filters={filters} />
        </div>
        <div className="border-right" />
        <div className="select-item ">
          <h6>Wie</h6>
          <AddGuest />
        </div>
      </div>
      <Link to={`/search?${searchParams.toString()}`} className="search-btn">
        <img src="/assets/img/search-normal.svg" alt="" /> Zoeken
      </Link>
      <div className="sm-device-search-box">
        <div className="d-flex align-items-center search-text gap-3">
          <img src="/assets/img/searchsm-icon.svg" alt=""/>
          <div>
            {" "}
            <h5>
              {currentType && currentDestination
                  ? `${currentType}, ${currentDestination}` :
                  currentType && !currentDestination ?
                      `${currentType}` :
                      !currentType && currentDestination ?
                          `${currentDestination}` : "Selecteer bestemming"
              }
            </h5>
            <p>
              {currentDuration ? currentDuration : "Selecteer reisduur"} •
              {participantAmountAdults || participantsAmountChildren
                  ? `${
                      participantAmountAdults > 0
                          ? participantAmountAdults + " Volwassenen"
                          : ""
                  } ${
                      participantAmountAdults > 0 && participantsAmountChildren > 0
                          ? ","
                          : ""
                  } ${
                      participantsAmountChildren > 0
                          ? participantsAmountChildren + " Kinderen"
                          : ""
                  }`
                  : " Selecteer reisgezelschap"}
            </p>
          </div>
        </div>

        <button className="filter-btn border-0" onClick={handleShow}>
          <img src="/assets/img/filter.svg" alt=""/>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
