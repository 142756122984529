import React from "react";
import Hero from "./hero/Hero";
import Immerse from "./Immerse/Immerse";
import TravelPackage from "./TravelPackage/TravelPackage";
import ExplorePlace from "./ExplorePlace/ExplorePlace";
import ReadyFor from "./ReadyFor/ReadyFor";
import ClearAnswer from "../common/ClearAnswer/ClearAnswer";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <Immerse />
      <TravelPackage />
      <ExplorePlace />
      <ReadyFor />
      <ClearAnswer />
    </>
  );
};

export default Home;
