import {ActionWithPayload} from "../types/redux/ActionWithPayload";
import {FilterSearch} from "../types/types";
import {SET_ACTIVE_FILTERS} from "../actions";

export interface FilterReducerState {
    selectedFilters: Array<FilterSearch>;
    filterChanged: boolean;
}

const initialState: FilterReducerState = {
    selectedFilters: [],
    filterChanged: false
}

const FilterReducer = (state: FilterReducerState = initialState, action: ActionWithPayload<any>): FilterReducerState => {
    switch (action.type) {
        case SET_ACTIVE_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload,
                filterChanged: true
            }
        default:
            return state;
    }
}

export default FilterReducer;