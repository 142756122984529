import PluginOptions from '../types/PluginOptions';

export default class ConfigUtil {

  public static readEnv(key: string, defaultValue: string): string {
    return process.env[key] && process.env[key] !== '' ? process.env[key] : defaultValue;
  }

  public static getConfig(): PluginOptions {

    // @ts-ignore window object is not set initial
    return window.vjv_compare_tool ? window.vjv_compare_tool : {
      partial: 'full-page', // options: full-page, full-tool, search-bar, result-page, filter-bar
      blocks: 0,
      blockSize: 'none', // options: small, medium, large
      apiBaseUrl: ConfigUtil.readEnv('REACT_APP_API_BASE_URL', "https://api.vergelijkjevakantie.app"),
      standalone: true,
    };
  }

}
