import Home from './components/home/Index';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/global/Footer/Footer';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import Search from './components/search/Index';
import React, {useState} from 'react';
import PluginOptions from './types/PluginOptions';
import SearchBar from './components/home/SearchBar/SearchBar';
import TravelPackage from "./components/search/TravelPackage/TravelPackage";
import FilterBar from "./components/search/Filter/Filter";
import {FilterSearch, SortOptions} from "./types/types";
import {vacationService} from "./services/VacationService";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "./types/redux/ReducerStateTypes";
import SearchResults from "./components/search/SearchResults/SearchResults";
import ConfigUtil from './utils/ConfigUtil';


function App() {


  const [vacations, setVacations] = useState([]);
  const [vacationCount, setVacationCount] = useState(0);

  const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
  const participantAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
  const currentSortOptions: SortOptions | null = useSelector((state: ReducerStateTypes) => state.sorting.currentSortOption);
  const filterChanged: boolean = useSelector((state: ReducerStateTypes) => state.filter.filterChanged);
  const pageLimit: number = useSelector((state: ReducerStateTypes) => state.page.pageLimit);
  const [searchParams] = useSearchParams();

  const filterVacations = (activeFilters: FilterSearch[]) => {
      vacationService.getFilteredVacations(
          activeFilters,
          (participantAmountAdults + participantAmountChildren),
          currentSortOptions,
          !filterChanged,
          searchParams.get("page"),
          pageLimit,
          searchParams.get("filter") ?? ""
      ).then(vacations => {
          setVacations(vacations.result);
          setVacationCount(vacations.count);
      });
  }

  const pluginOptions = ConfigUtil.getConfig();

  console.log({fn: 'App', pluginOptions})

  if(pluginOptions.partial !== 'full-page') {
    switch (pluginOptions.partial) {
        case 'full-tool':
            return <TravelPackage
                        filterVacations={filterVacations}
                        vacations={vacations}
                        vacationCount={vacationCount}
                    />
        case 'search-bar':
            return <SearchBar />
        case 'result-page':
            return <SearchResults
                        vacationCount={vacationCount}
                        vacations={vacations}
                    />
        case 'filter-bar':
            return <FilterBar filterVacations={filterVacations} />;
        default:
            console.error('Invalid pluginOptions: partial must be one of full-page, full-tool, search-bar, filter-bar, result-page, result-blocks');
            return null;
    }
  }

  return (
      <>
        <Routes>
          <Route
              path="/"
              element={
                <Home />
              }
          />
          <Route
              path="/search"
              element={
                <Search filterVacations={filterVacations} />
              }
          />
        </Routes>
        <Footer/>
      </>
  );
}

export default App;
