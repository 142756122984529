import {ActionWithPayload} from "../types/redux/ActionWithPayload";
import {SET_PAGE_LIMIT} from "../actions/PageAction";

export interface PageReducerState {
    pageLimit: number;
}

const initialState: PageReducerState = {
    pageLimit: 10
}

const PageReducer = (state: PageReducerState = initialState, action: ActionWithPayload<any>): PageReducerState => {
    switch (action.type) {
        case SET_PAGE_LIMIT:
            return {
                ...state,
                pageLimit: action.payload
            }
        default:
            return state;
    }
}

export default PageReducer;