import {FilterSearch} from "../types/types";
import DurationUtil from "./DurationUtil";
import UrlUtil from "./UrlUtil";
import DateUtil from "./DateUtil";

export type FilterCategory = {
    filterType: string;
    filterSubCategory?: string;
}

export default class RangeUtil {

    /**
     * List of filter types that should be treated as ranges,
     * this means they have minimum and maximum values.
     */
    public static RANGE_FILTERS: FilterCategory[] = [
        {filterType: "duration"},
        {filterType: "departure", filterSubCategory: "date"},
        {filterType: "price"},
        {filterType: "flexible", filterSubCategory: "departure"}
    ];

    /**
     * Adds correctly formatted range filters to the given URL with minimum and maximum values.
     *
     * @param {FilterSearch[]} filters - A list of active filters that need to be added to the URL.
     * @param {string} url - The base URL to which the new filter parameters will be added.
     *
     * @returns {string} - The URL with all given range filter parameters added, including min and max values.
     *
     * @example
     * // Example usage:
     * const baseUrl = "https://example.com/recieve?";
     * const activeFilters = [{
     *   filterCategory: "vacation",
     *   filterType: "duration",
     *   value: "1-3 dagen"
     *  }];
     * const updatedUrl = addRangeFiltersToUrl(activeFilters, baseUrl);
     *
     * console.log(updatedUrl);
     * // Outputs: "https://example.com/recieve?filter[vacation][duration][min]=1&filter[vacation][duration][max]=3"
     */

    public static addRangeFiltersToUrl(filters: FilterSearch[], url: string): string {
        filters.forEach(filter => {

            const filterComparison: string = `${filter.filterType}:${filter.filterSubCategory}`;

            switch (filterComparison) {
                case "duration:undefined":
                    url = UrlUtil.addRangeToUrlParams(
                        url,
                        filter,
                        DurationUtil.parseDurationDays(filter.value)
                    );
                    break;
                case "departure:date":
                    const flexible = filters.find(f => f.filterType === "flexible" && f.filterSubCategory === "departure");
                    let range: {min: string, max: string};
                    if (flexible) {
                        range = {min: DateUtil.formatDate(filter.value, -parseInt(flexible.value)), max: DateUtil.formatDate(filter.value, parseInt(flexible.value)+1)}
                    } else {
                        range = {min: DateUtil.formatDate(filter.value, 0), max: DateUtil.formatDate(filter.value, 1)}
                    }
                    url = UrlUtil.addRangeToUrlParams(
                        url,
                        filter,
                        range
                    );
                    break;
                case "price:undefined":
                    url = UrlUtil.addRangeToUrlParams(
                        url,
                        filter,
                        {min: filter.value.split("-")[0], max: filter.value.split("-")[1]}
                    );
                default:
                    return url;
            }
        });

        return url;
    }
}