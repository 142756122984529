import React from "react";
import Header from "../../global/header/Header";
import "./Hero.scss";
import SearchBar from "../SearchBar/SearchBar";

interface SearchHeroProps {
  handleShow: () => void;
}

const SearchHero: React.FC<SearchHeroProps> = ({
  handleShow,
}) => {
  return (
    <header id="search-hero">
      <div className="container">
        <div className="head-contents">
          <Header styleFor="search" />
        </div>
        <button className="outline-btn">We've Got Your Wings Covered</button>
        <h1>Your dream trip starts here, compare & book your flight</h1>
        <div className="search-box">
          <SearchBar
            handleShow={handleShow}
          />
        </div>
      </div>
    </header>
  );
};

export default SearchHero;
