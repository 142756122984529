import {Store} from "redux";
import rootReducer from "./reducers";
import {configureStore} from "@reduxjs/toolkit";

function getStore(): Store {
    // @ts-ignore
    if (window.store) { return window.store as Store; }

    const store: Store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        devTools: process.env.NODE_ENV !== "production"
    });

    // @ts-ignore
    window.store = store;

    return store;
}
const store: Store = getStore();
export default store;