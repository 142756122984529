export default class DateUtil {
    /**
     * Parses a date-string with the format "dd Mmm, yyyy" into "yyyy-mm-dd".
     * Returns "Parse Error" if an error occurs during parsing.
     *
     * @param {string} date - The date string to be parsed, in the format "dd Mmm, yyyy", e.g., "13 Jun, 2024".
     * @param flexible flexible - The number of days to add to the date.
     * @returns {string} - The parsed date string in the format "yyyy-mm-dd", e.g., "2024-06-13".
     *
     * @throws {string} Throws "Parse Error" if a problem occurs during parsing.
     *
     * @example
     * // Example usage:
     * const dateString = "13 Jun, 2024";
     * const parsedDate = parseDateString(dateString);
     * console.log(parsedDate); // Outputs: "2024-06-13"
     */

    public static formatDate(date: string, flexible: number): string {
        try {
            const parsedDate: Date = new Date(date.replace(/\s*,\s*/g, ' '));
            parsedDate.setDate(parsedDate.getDate() + flexible);
            const year: number = parsedDate.getFullYear();
            const month: string = String(parsedDate.getMonth() + 1).padStart(2, '0');
            const day: string = String(parsedDate.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        } catch (e) {
            return "Parse Error";
        }
    }

    public static formatMonth(monthYear: string): string {
        const months = {
            'januari': '01',
            'februari': '02',
            'maart': '03',
            'april': '04',
            'mei': '05',
            'juni': '06',
            'juli': '07',
            'augustus': '08',
            'september': '09',
            'oktober': '10',
            'november': '11',
            'december': '12',
        };

        const [month, year] = monthYear.toLowerCase().split(' ');

        return `${year}-${months[month]}`;
    }
}