import React, { useEffect, useRef, useState } from "react";
import "./SelectLocation.scss";
import "../SelectDestination/SelectDestination.scss";
import {FilterSearch} from "../../../types/types";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../actions";
import UrlUtil from "../../../utils/UrlUtil";
import * as url from "node:url";
import FilterUtil from "../../../utils/FilterUtil";

interface SelectLocationProps {
  showing: string;
  vacationTypes: Array<{displayValue: string, value: string, count: number}>;
}

const SelectLocation: React.FC<SelectLocationProps> = ({
  showing,
  vacationTypes
}) => {
  const [isOpenAddLocationDropDown, setIsopenAddLocationDropdown] =
    useState(false);
  const [isOpenDestination, setIsOpenDestnation] = useState(false);
  const [currentVacationType, setVacationType] = useState<FilterSearch | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const dispatch = useDispatch();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const onOutsideClick = () => {
    setIsopenAddLocationDropdown(false);
    setIsOpenDestnation(false);
  };
  useEffect(() => {
    const selectedType = selectedFilters.find(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
    if (selectedType === undefined) {
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const typeFilter: FilterSearch = urlFilters.find(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
        setVacationType(typeFilter);
    } else {
        setVacationType(selectedType);
    }
  }, [searchParams, selectedFilters, setVacationType]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutsideClick]);

  const handleVakatietype = (vk: string) => {
    setIsopenAddLocationDropdown(false);

    let urlFilters = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
    let activeFilters = [...selectedFilters, ...urlFilters];
    const index: number = activeFilters.findIndex(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
    const filter: FilterSearch = {
      filterCategory: "vacation",
      filterType: "type",
      value: vk,
      displayValue: vk
    };
    console.log(index)
    if (index > -1) {
        activeFilters.splice(index);
    }
    activeFilters.push(filter);

    setSearchParams(params => {
      params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters])));
      return params;
    })
    dispatch(setActiveFilters([...activeFilters]));
  };
  return (
    <div id="select-location" ref={wrapperRef}>
      <h5
        onClick={() => setIsopenAddLocationDropdown(!isOpenAddLocationDropDown)}
      >
        {currentVacationType?.displayValue === undefined
          ? showing
          : currentVacationType.displayValue}
      </h5>

      {isOpenAddLocationDropDown && (
          <div className="select-destination-contents">
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <h3 style={{wordBreak: "break-word", width: "55%"}}>Selecteer uw vakantie type</h3>
              {
                currentVacationType ? <a onClick={() => {
                  const activeFilters = selectedFilters.filter(filter => filter.filterType !== "type" && filter.filterCategory !== "vacation");
                  setSearchParams(params => {
                      params.set("filter", UrlUtil.parseFiltersToUrlParams(activeFilters));
                      return params;
                  });
                  dispatch(setActiveFilters([...activeFilters]));
                }} style={{cursor: "pointer", height: "min-content"}}>Verwijder keuze</a> : null
              }
            </div>

            <div className="destination-grid-contents">
              <div
                  className="single-item"
                  onClick={() => {
                    setIsopenAddLocationDropdown(false);
                    const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
                    const activeFilters: FilterSearch[] = [...selectedFilters, ...urlFilters];
                    const index: number = activeFilters.findIndex(filter => (filter.filterType === "type" || filter.filterType === "all_types") && filter.filterCategory === "vacation");
                    const filter: FilterSearch = {
                      filterCategory: "vacation",
                      filterType: "all_types",
                      filterSubCategory: "default",
                      value: "",
                      displayValue: "Alle vakantie types",
                      count: vacationTypes.reduce((acc, country) => acc + country.count, 0)
                    };
                    setVacationType(filter)
                    if (index > -1) {
                      activeFilters.splice(index)
                    }

                    activeFilters.push(filter);

                    setSearchParams(params => {
                      params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters])));
                      return params;
                    });
                    dispatch(setActiveFilters([...activeFilters]));
                  }}
              >
                {/*<img src={item.img} alt="" />*/}
                <div className="text-content">
                    Alle vakantie types ({vacationTypes.reduce((acc, item) => acc + item.count, 0)})
                </div>
              </div>
              {vacationTypes.map((item, index) => (
                  <div
                      key={index}
                      className="single-item"
                      onClick={() => handleVakatietype(item.value)}
                  >
                    {/*<img src={item.img} alt="" />*/}
                    <div className="text-content">
                      {item.displayValue}&nbsp;({item.count})
                    </div>
                  </div>
              ))}
            </div>
          </div>
      )}
    </div>
  );
};

export default SelectLocation;
