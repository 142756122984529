export default class DurationUtil {

    /**
     * Parses a duration string in days into an object with minimum and maximum values.
     *
     * @param {string} durationString - A string representing a duration with min and max values, e.g., "1-3 days".
     *
     * @returns {{min: number; max: number}} - An object containing the minimum and maximum values.
     *
     * @example
     * // Example usage:
     * const duration = "1-3 days";
     * const result = parseDurationString(duration);
     * console.log(result); // Outputs: { min: 1, max: 3 }
     */
    public static parseDurationDays(durationString: string): { min: number; max: number } {
        const numbers: number[] = durationString
                                    .split(durationString.includes("-") ? "-" : " ")
                                    .map(part => part.replace(/[^0-9]/g, "").trim())
                                    .map(part => parseInt(part)).filter(part => !isNaN(part));

        return { min: Math.min(...numbers), max: Math.max(...numbers)};

    }

    public static parseDaysToWeeks(days: string): string {
        const weeksAmount = days.split(" ")
            .map(part => part.replace(/[^0-9]/g, "").trim())
            .map(part => parseInt(part) === 0 ? 0 : parseInt(part) / 7)[0];

        return weeksAmount === 1 ? `1 week` : `${weeksAmount} weken`;

    }

    public static renderDurationDisplayValue(value: string): string {
        const days: { min: number; max: number } = DurationUtil.parseDurationDays(value);
        if (days.min === days.max && days.min % 7 === 0) {
            return DurationUtil.parseDaysToWeeks(value);
        }

        return value;
    }
}