import {combineReducers} from "redux";
import FilterReducer from "./FilterReducer";
import ParticipantReducer from "./ParticipantReducer";
import SortReducer from "./SortReducer";
import PageReducer from "./PageReducer";

const rootReducer = combineReducers({
    filter: FilterReducer,
    participant: ParticipantReducer,
    sorting: SortReducer,
    page: PageReducer
});

export default rootReducer;