import {FilterSearch, SortOptions, Vacation} from "../types/types";
import {apiService} from "./ApiService";
import UrlUtil from "../utils/UrlUtil";
import RangeUtil from "../utils/RangeUtil";
import FilterUtil from "../utils/FilterUtil";

class VacationService {
    public getFilteredVacations(
        activeFilters: FilterSearch[],
        participantAmount: number,
        sortOptions: SortOptions = null,
        useDefaultFilters: boolean,
        page: number | string,
        limit: number,
        urlParams: string
    ): Promise<{result: Vacation[], count: number} | null> {
        return new Promise((resolve, reject) => {
            activeFilters = UrlUtil.parseUrlParamsToFilters(urlParams);
            if (activeFilters.length === 0 && useDefaultFilters) {
                activeFilters = FilterUtil.getDefaultFilters();
            }
            let url: string = UrlUtil.formatUrlParams('vacation', activeFilters, [
                {filterType: "all_countries", filterSubCategory: "default"},
                {filterType: "all_types", filterSubCategory: "default"}
                , ...RangeUtil.RANGE_FILTERS
            ]);

            url = RangeUtil.addRangeFiltersToUrl(activeFilters, url);
            if (activeFilters.length > 0) {
                url = UrlUtil.addParticipantAmountToUrlParams(url, participantAmount);
                if (sortOptions !== null) {
                    url = UrlUtil.addSortingToUrlParams(url, sortOptions);
                }
            }

            url = url.concat(`${activeFilters.length === 0 ? "?" : "&"}page=${page}&limit=${limit}`);

            apiService.call<{result: Vacation[], count: number}>(url)
                .then(response => {
                    return resolve(response.data);
                }).catch(reject);
        });
    }
}

export const vacationService: VacationService = new VacationService();