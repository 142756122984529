import React, {useEffect, useState} from "react";
import {FilterSearch} from "../../../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../../../actions";
import "./SelectCity.scss";
import FilterUtil from "../../../../../utils/FilterUtil";
import {useSearchParams} from "react-router-dom";
import UrlUtil from "../../../../../utils/UrlUtil";

interface SelectCityProps {
    region: { displayValue: string, value: string, count: number };
    setCity: (value: string | undefined) => void;
    setRegion: (value: string | undefined) => void;
    cities: Array<{ displayValue: string, value: string, region: string, count: number }>;
}

const SelectCity: React.FC<SelectCityProps> = ({
       region,
       setCity,
       setRegion,
       cities
   }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const dispatch = useDispatch();

    const handleCity = (e: React.ChangeEvent<HTMLInputElement>, city: {
        displayValue: string,
        value: string,
        count: number
    }) => {
        const index: number = selectedFilters.findIndex(f => f.value === city.value && f.filterType === "city");
        let activeFilters: FilterSearch[] = [...selectedFilters];
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const urlIndex: number = urlFilters.findIndex(f => f.value === city.value && f.filterType === "city");

        if (e.target.checked && index === -1) {
            activeFilters = [...selectedFilters, {
                filterCategory: "accommodation",
                filterType: "city",
                value: city.value,
                displayValue: city.displayValue
            }];

            setCity(city.displayValue);
        } else {
            activeFilters.splice(index, 1);
            urlFilters.splice(urlIndex, 1);
            setCity(undefined);
        }

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters, ...urlFilters])));
            return params;
        });
        dispatch(setActiveFilters([...activeFilters]));
    }

    const handleRegion = (e: React.ChangeEvent<HTMLInputElement>, region: {
        displayValue: string,
        value: string,
        count: number
    }) => {
        const index: number = selectedFilters.findIndex(f => f.value === region.value && f.filterType === "region");
        let activeFilters: FilterSearch[] = [...selectedFilters];
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const urlIndex: number = urlFilters.findIndex(f => f.value === region.value && f.filterType === "region");

        if (e.target.checked && index === -1) {
            activeFilters = [...selectedFilters, {
                filterCategory: "accommodation",
                filterType: "region",
                value: region.value,
                displayValue: region.displayValue
            }];

            setRegion(region.displayValue);
        } else {
            const cityIndex: number = selectedFilters.findIndex(f => f.filterType === "city");
            if (cityIndex > -1) {
                activeFilters.splice(cityIndex, 1);
            }
            activeFilters.splice(index, 1);
            urlFilters.splice(urlIndex, 1);
            setRegion(undefined);
        }
        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters, ...urlFilters])));
            return params;
        });
        dispatch(setActiveFilters([...activeFilters]));
    }

    const filterExists = (callback: (f: FilterSearch) => boolean, condition: "or" | "and") => {
        if (condition === "or") {
            return selectedFilters.findIndex(callback) > -1 || UrlUtil.parseUrlParamsToFilters(searchParams.get("filter")).findIndex(callback) > -1;
        }
        if (condition === "and") {
            return selectedFilters.findIndex(callback) > -1 && UrlUtil.parseUrlParamsToFilters(searchParams.get("filter")).findIndex(callback) > -1;
        }
    }

    return (
        <>
            <button className="select-btn">
                <input
                    type="checkbox"
                    name=""
                    id={region.value}
                    onChange={(e) => {
                        handleRegion(e, region);
                    }}
                    checked={filterExists(f => f.value === region.value, "or")}
                />{" "}
                <label htmlFor={region.value}>{`${region.displayValue} (${region.count})`}</label>
            </button>
            {filterExists(f => f.filterType === "region", "or") ?
                <div className={"select-btn-wrapper-list"}>
                    <button className="select-btn">
                        <input
                            type="checkbox"
                            name=""
                            id={"allCities"}
                            checked={!filterExists(f => f.filterType === "city", "or")}
                            onChange={(e) => {
                                const newFilters = FilterUtil.removeDuplicates([
                                    ...selectedFilters.filter(f => f.filterType !== "city"),
                                    ...UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"))
                                        .filter(f => f.filterType !== "city")
                                ]);
                                setSearchParams(params => {
                                    params.set("filter", UrlUtil.parseFiltersToUrlParams(newFilters));
                                    return params;
                                });
                                dispatch(setActiveFilters([...newFilters]));
                            }}
                        />{" "}
                        <label htmlFor={"allCities"}>{`Alle plaatsen (${region.count})`}</label>
                    </button>
                    {
                        cities.map((city, index) => {
                            return (
                                <button key={index} className="select-btn">
                                    <input
                                        type="checkbox"
                                        name=""
                                        id={city.value}
                                        checked={filterExists(f => f.value === city.value, "or")}
                                        onChange={(e) => handleCity(e, city)}
                                    />
                                    <label htmlFor={city.value}>{`${city.displayValue} (${city.count})`}</label>
                                </button>
                            )
                        })}
                </div>
                : null
            }
        </>
    )
}

export default SelectCity;

