import React, {useEffect, useState} from "react";
import "./SearchBar.scss";
import {Link, useSearchParams} from "react-router-dom";
import AddGuest from "../../common/AddGuest/AddGuest";
import SelectLocation from "../../common/SelectLocation/SelectLocation";
import EnterDate from "../../common/EnterDate/EnterDate";
import SelectDestination from "../../common/SelectDestination/SelectDestination";
import {Filter, FilterSearch} from "../../../types/types";
import {filterService} from "../../../services/FilterService";
import {FilterCategory} from "../../../types/filter/FilterCategory";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import ConfigUtil from '../../../utils/ConfigUtil';

const SearchBar: React.FC = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [vacationTypes, setVacationTypes] = useState([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [searchParams] = useSearchParams();
  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const pluginOptions = ConfigUtil.getConfig();


  useEffect(() => {
    filterService.getFilters(selectedFilters, searchParams.get("page") ?? "").then(filters => {
      if (filters.length === 0) return;
      setFilters(filters);
      const countries: Filter = filters.find(filter => filter.filterType === FilterCategory.COUNTRY);
      const regions: Filter = filters.find(filter => filter.filterType === FilterCategory.REGION);
      const cities: Filter = filters.find(filter => filter.filterType === FilterCategory.CITY);
      const types: Filter = filters.find(filter => filter.filterType === FilterCategory.VACATION_TYPE && filter.filterCategory === "vacation");

      setCountries(countries.values);
      setRegions(regions.values);
      setCities(cities.values);
      setVacationTypes(types.values);
    })
  }, [searchParams, selectedFilters]);

  return (
    <div id="search-bar">
      <div className="select-items-wrapper">
        <div className="select-item ">
          <h6>Vakantietype</h6>
          <SelectLocation
            showing="Selecteer vakantie type"
            vacationTypes={vacationTypes}
          />
        </div>
        <div className="border-right" />
        <div className="select-item t">
          <h6>Bestemming</h6>
          {/* <h5>Select Destination</h5> */}
          <SelectDestination
            showing="Selecteer bestemming"
            countries={countries}
            cities={cities}
            regions={regions}
          />
        </div>
        <div className="border-right" />
        <div className="select-item ">
          <h6>Wanneer</h6>
          <EnterDate filters={filters} />
        </div>
        <div className="border-right" />
        <div className="select-item ">
          <h6>Wie</h6>
          <AddGuest />
        </div>
      </div>
      { pluginOptions.standalone !== undefined && pluginOptions.standalone === true ?
          <Link to={`/search?${searchParams.toString()}`} className="search-btn">
            <img src="/assets/img/search-normal.svg" alt=""/> Zoeken
          </Link>
          :
          <a href={`/search?${searchParams.toString()}`} className="search-btn">
            <img src="/assets/img/search-normal.svg" alt=""/> Zoeken
          </a>
      }
    </div>
  );
};

export default SearchBar;
