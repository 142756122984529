import {ActionWithPayload} from "../types/redux/ActionWithPayload";
import {SortOptions} from "../types/types";
import {SET_SORTING_OPTIONS} from "../actions/SortAction";

export interface SortReducerState {
    currentSortOption: SortOptions | null;
}

const initialState: SortReducerState = {
    currentSortOption: {
        name: "price",
        sortDirection: "asc"
    }
}

const SortReducer = (state: SortReducerState = initialState, action: ActionWithPayload<SortOptions>) => {
    switch (action.type) {
        case SET_SORTING_OPTIONS:
            return {
                ...state,
                currentSortOption: action.payload
            }
        default:
            return state;
    }
}

export default SortReducer;