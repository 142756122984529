import React, {useEffect} from "react";
import { LeftArrowWithBg, RightArrowWithBg } from "../../../Icons";
import "./Pagination.scss";
import {useSearchParams} from "react-router-dom";

interface PaginationProps {
  vacationCount: number;
  pageSize: number;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  vacationCount,
  pageSize,
  currentPage
}) => {

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams(params => {
        params.set("page", "1");
        return params;
      });
    }
  }, [searchParams, setSearchParams]);

  const getPageRange = (maxNumber: number, currentNumber: number = 1) => {
    const rangeSize = 3;
    let start = Math.max(currentNumber - 1, 1);

    if (start + rangeSize - 1 > maxNumber) {
      start = maxNumber - rangeSize + 1;
    }

    if (start < 1) {
      start = 1;
    }

    const end = Math.min(start + rangeSize - 1, maxNumber);
    let result = [];

    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    return result;
  }

  const maxPage: number = Math.ceil(vacationCount/pageSize);
  const pageRange: number[] = getPageRange(maxPage, currentPage);

  return (
      <div id="pagination">
        <div className="pagination-wrapper">
          <button onClick={() => {
            setSearchParams(params => {
              params.set("page", `${currentPage > 1 ? currentPage - 1 : currentPage}`);
              window.scrollTo(0, 0);
              return params;
            })
          }}>
            <div className="item">
              <LeftArrowWithBg/>
            </div>
          </button>

          {
            ![1, 2].includes(currentPage) ?
                <div className="first-item">
                  <button onClick={() => {
                    setSearchParams(params => {
                      params.set("page", "1");
                      window.scrollTo(0, 0);
                      return params;
                    })
                  }}>
                    <div className="item">1</div>
                  </button>
                  {
                    pageRange.includes(1) ?
                        null :
                        <span>....</span>
                  }
                </div> : null
          }

          { pageRange.map((page, index) => {
            return <button key={index} onClick={() => {
              setSearchParams(params => {
                params.set("page", `${isNaN(page) ? 1 : page}`);
                window.scrollTo(0, 0);
                return params;
              })
            }}><div key={index} className={page === currentPage ? "item active" : "item"}>{isNaN(page) ? 1 : page}</div></button>
          }) }

          {
            pageRange.includes(maxPage) ?
                null :
                <div className="final-item">
                  {
                    isNaN(maxPage) ? null : <span>....</span>
                  }
                  <button onClick={() => {
                    setSearchParams(params => {
                      params.set("page", `${isNaN(maxPage) ? 1 : maxPage}`);
                      window.scrollTo(0, 0);
                      return params;
                    })
                  }}>
                    <div className="item">{isNaN(maxPage) ? 1 : maxPage}</div>
                  </button>
                </div>
          }

          <button onClick={() => {
            setSearchParams(params => {
              params.set("page", `${currentPage < maxPage ? currentPage + 1 : currentPage}`);
              window.scrollTo(0, 0);
              return params;
            })
          }}>
            <div className="item">
              <RightArrowWithBg/>
            </div>
          </button>
        </div>
      </div>
  );
};

export default Pagination;
