import React from "react";
import SelectCity from "./SelectCity/SelectCity";
import {FilterSearch} from "../../../../types/types";
import "./SelectRegion.scss";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../../actions";
import {useSearchParams} from "react-router-dom";
import UrlUtil from "../../../../utils/UrlUtil";
import FilterUtil from "../../../../utils/FilterUtil";

interface SelectRegionProps {
    show: boolean;
    country: FilterSearch | undefined;
    countryCount: number;
    setRegion: (value: string | undefined) => void;
    setCity: (value: string | undefined) => void;
    regions: Array<{ displayValue: string, value: string, count: number }>;
    cities: Array<{ displayValue: string, value: string, region: string, count: number }>;

}

const SelectRegion: React.FC<SelectRegionProps> = ({
    show,
    country,
    countryCount,
    setRegion,
    setCity,
    regions,
    cities
}) => {

    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    if (!show) {
        return null;
    }

    return (
        <div className="region-wrapper">
            <h5>{country.displayValue} ({countryCount})</h5>
            <div className="select-btn-wrapper">
                <button className="select-btn">
                    <input
                        type="checkbox"
                        name=""
                        id={"allCities"}
                        checked={
                        selectedFilters.findIndex(f => f.filterType === "region") === -1 &&
                        UrlUtil.parseUrlParamsToFilters(searchParams.get("filter")).findIndex(f => f.filterType === "region") === -1}
                        onChange={(e) => {
                          const newFilters = FilterUtil.removeDuplicates([
                              ...selectedFilters.filter(f => f.filterType !== "region" && f.filterType !== "city"),
                              ...UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"))
                                            .filter(f => f.filterType !== "region" && f.filterType !== "city")
                          ]);
                          setSearchParams(params => {
                              params.set("filter", UrlUtil.parseFiltersToUrlParams(newFilters));
                              return params;
                          });
                          dispatch(setActiveFilters([...newFilters]));
                        }}
                    />{" "}
                    <label htmlFor={"allCities"}>{`Alle gebieden (${countryCount})`}</label>
                </button>

                {
                    regions?.map((region, index) => {
                        return <SelectCity
                            key={index}
                            region={region}
                            setCity={setCity}
                            setRegion={setRegion}
                            cities={cities}
                        />
                    })
                }
            </div>
        </div>

    );
}

export default SelectRegion;