import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import ClearAnswer from "../common/ClearAnswer/ClearAnswer";
import FilterBar from "./Filter/Filter";
import HaveYou from "./HaveYou/HaveYou";
import SearchHero from "./Hero/Hero";
import TravelPackage from "./TravelPackage/TravelPackage";
import {FilterSearch, SortOptions} from "../../types/types";
import {vacationService} from "../../services/VacationService";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../types/redux/ReducerStateTypes";
import {useSearchParams} from "react-router-dom";

interface SearchBarProps {
  filterVacations: (value: FilterSearch[]) => void;
}

const Search: React.FC<SearchBarProps> = ({
  filterVacations
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [vacations, setVacations] = useState([]);
  const [vacationCount, setVacationCount] = useState(0);

  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
  const participantAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
  const currentSortOptions: SortOptions | null = useSelector((state: ReducerStateTypes) => state.sorting.currentSortOption);
  const filterChanged: boolean = useSelector((state: ReducerStateTypes) => state.filter.filterChanged);
  const pageLimit: number = useSelector((state: ReducerStateTypes) => state.page.pageLimit);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    vacationService.getFilteredVacations(
        selectedFilters,
        (participantAmountAdults + participantAmountChildren),
        currentSortOptions,
        !filterChanged,
        searchParams.get("page"),
        pageLimit,
        searchParams.get("filter") ?? ""
    ).then(vacations => {
      setVacations(vacations.result);
      setVacationCount(vacations.count);
    });
  }, [currentSortOptions, filterChanged, pageLimit, participantAmountAdults, participantAmountChildren, searchParams, selectedFilters]);

  return (
    <>
      <SearchHero handleShow={handleShow} />
      <TravelPackage
        filterVacations={filterVacations}
        vacations={vacations}
        vacationCount={vacationCount}
      />
      <ClearAnswer />
      <HaveYou />

      <>
        <Offcanvas
          className="filter-offcanvas"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filtering</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FilterBar filterVacations={filterVacations}/>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </>
  );
};

export default Search;
