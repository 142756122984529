export const ArrowUpRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1_133)">
        <path
          d="M17.5 7L7.5 17"
          stroke="#2563EB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 7H17.5V16"
          stroke="#2563EB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_133">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.5 5L7.5 10L12.5 15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.5 5L12.5 10L7.5 15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GreenStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M16 6.70718H9.89016L8.00295 0.892212L6.10984 6.70718L0 6.70128L4.94803 10.2988L3.05492 16.1078L8.00295 12.5162L12.9451 16.1078L11.0579 10.2988L16 6.70718Z"
        fill="#00B67A"
      />
      <path
        d="M11.4825 11.614L11.0579 10.2988L8.00293 12.5163L11.4825 11.614Z"
        fill="#005128"
      />
    </svg>
  );
};

export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.176 3.31203C10.592 3.48803 10.224 4.76805 10.072 6.32805C5.82398 6.32805 2 10.7842 2 16.0642C3.752 11.2642 7.59996 9.96005 10.112 9.96005C10.272 11.368 10.632 12.4962 11.176 12.6562C12.856 13.1442 18 9.95213 18 7.98409C18 6.01609 12.856 2.82403 11.176 3.31203Z"
        fill="white"
      />
    </svg>
  );
};
export const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M2.56416 6.01371C3.95958 -0.120456 13.0475 -0.113372 14.4358 6.02079C15.2504 9.61913 13.0121 12.665 11.05 14.5491C9.62625 15.9233 7.37375 15.9233 5.94291 14.5491C3.98791 12.665 1.74958 9.61204 2.56416 6.01371Z"
        fill="#FB923C"
      />
      <path
        d="M8.50004 9.5129C9.72059 9.5129 10.71 8.52344 10.71 7.3029C10.71 6.08235 9.72059 5.0929 8.50004 5.0929C7.27949 5.0929 6.29004 6.08235 6.29004 7.3029C6.29004 8.52344 7.27949 9.5129 8.50004 9.5129Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1_265)">
        <path
          d="M19 12H5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 12L13 18"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 12L13 6"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_265">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_1_2090)">
        <mask
          id="mask0_1_2090"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="22"
        >
          <path d="M0 3.8147e-06H22V22H0V3.8147e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1_2090)">
          <path
            d="M21.141 11.001C21.1388 11.9053 21.1286 12.8097 21.1162 13.7139C21.1013 14.805 21.0844 15.8965 20.8108 16.9688C20.5239 18.0933 19.958 19.0631 19.0776 19.781C18.1498 20.5376 17.0284 20.9353 15.8181 21.0089C14.2127 21.1065 12.6067 21.1451 11.0008 21.1413C9.39479 21.1451 7.78879 21.1065 6.18352 21.0089C4.97318 20.9353 3.85178 20.5376 2.924 19.781C2.04357 19.0631 1.47767 18.0933 1.19073 16.9688C0.9171 15.8965 0.900257 14.805 0.885347 13.7139C0.872972 12.8097 0.862701 11.9053 0.860596 11.001C0.862701 10.0967 0.872972 9.1923 0.885347 8.28802C0.900257 7.19701 0.9171 6.10551 1.19073 5.03319C1.47767 3.90869 2.04357 2.93889 2.924 2.22092C3.85178 1.46433 4.97318 1.06665 6.18352 0.993048C7.78879 0.895423 9.39479 0.856837 11.0008 0.860619C12.6067 0.856837 14.2127 0.895423 15.8181 0.993048C17.0284 1.06665 18.1498 1.46433 19.0776 2.22092C19.958 2.93889 20.5239 3.90869 20.8108 5.03319C21.0844 6.10551 21.1013 7.19701 21.1162 8.28802C21.1286 9.1923 21.1388 10.0967 21.141 11.001Z"
            stroke="#D4D4D8"
            strokeWidth="1.84895"
            strokeMiterlimit="10"
          />
          <path
            d="M15.4688 11.0007C15.4688 13.4925 13.4488 15.5125 10.9571 15.5125C8.46532 15.5125 6.44536 13.4925 6.44536 11.0007C6.44536 8.50897 8.46532 6.48901 10.9571 6.48901C13.4488 6.48901 15.4688 8.50897 15.4688 11.0007Z"
            stroke="#D4D4D8"
            strokeWidth="1.84895"
            strokeMiterlimit="10"
          />
          <path
            d="M18.2196 5.19971C18.2196 5.91161 17.6425 6.48877 16.9306 6.48877C16.2187 6.48877 15.6415 5.91161 15.6415 5.19971C15.6415 4.4878 16.2187 3.91064 16.9306 3.91064C17.6425 3.91064 18.2196 4.4878 18.2196 5.19971Z"
            fill="#D4D4D8"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_2090">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M10.3932 18V9.78996H13.1479L13.5612 6.58941H10.3932V4.54632C10.3932 3.61998 10.6494 2.98869 11.9793 2.98869L13.6727 2.98799V0.125307C13.3798 0.0872508 12.3746 0 11.2046 0C8.76146 0 7.08884 1.49127 7.08884 4.22934V6.58941H4.32582V9.78996H7.08884V18H10.3932Z"
        fill="#D4D4D8"
      />
    </svg>
  );
};

export const TikTok = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11.451 0.707373C12.5764 0.690186 13.6936 0.699123 14.8101 0.690186C14.8473 2.0865 15.4138 3.34325 16.3151 4.27412L16.3137 4.27275C17.2838 5.14656 18.546 5.71306 19.9375 5.80931L19.9568 5.81069V9.27294C18.6423 9.23994 17.4061 8.93675 16.2917 8.41562L16.3481 8.439C15.8091 8.17981 15.3533 7.91375 14.9201 7.61675L14.9559 7.64012C14.9476 10.1488 14.9641 12.6575 14.9387 15.1572C14.8679 16.4312 14.4444 17.5931 13.7651 18.5631L13.7789 18.5418C12.6431 20.1684 10.8034 21.2361 8.71132 21.2994H8.70169C8.61713 21.3035 8.51744 21.3056 8.41707 21.3056C7.22769 21.3056 6.116 20.9742 5.16863 20.3987L5.19613 20.4146C3.47188 19.3771 2.2825 17.602 2.0625 15.5374L2.05975 15.5092C2.04257 15.0796 2.03432 14.6499 2.0515 14.2291C2.38838 10.9436 5.14113 8.40187 8.48719 8.40187C8.86326 8.40187 9.23176 8.43419 9.58994 8.49537L9.55144 8.48987C9.56863 9.76106 9.51707 11.0329 9.51707 12.3041C9.22626 12.1989 8.89076 12.1377 8.54082 12.1377C7.25657 12.1377 6.16413 12.9586 5.75988 14.1047L5.75369 14.1253C5.66226 14.4189 5.60932 14.7564 5.60932 15.1057C5.60932 15.2473 5.61825 15.3876 5.63475 15.5251L5.63338 15.5086C5.86163 16.9152 7.06751 17.9767 8.52157 17.9767C8.56351 17.9767 8.60476 17.976 8.64601 17.9739H8.63982C9.64563 17.9437 10.5188 17.4026 11.0124 16.6031L11.0193 16.5907C11.2028 16.3349 11.3286 16.0256 11.3706 15.6894L11.3713 15.6797C11.4572 14.1418 11.4228 12.6128 11.4311 11.0749C11.4393 7.61262 11.4228 4.15862 11.4483 0.705311L11.451 0.707373Z"
        fill="#D4D4D8"
      />
    </svg>
  );
};

export const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M22.5 7H16.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 7H2.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10.5C12.433 10.5 14 8.933 14 7C14 5.067 12.433 3.5 10.5 3.5C8.567 3.5 7 5.067 7 7C7 8.933 8.567 10.5 10.5 10.5Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 18H18.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 18H2.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 21.5C16.433 21.5 18 19.933 18 18C18 16.067 16.433 14.5 14.5 14.5C12.567 14.5 11 16.067 11 18C11 19.933 12.567 21.5 14.5 21.5Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseSideBarArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85057 3.8753C8.14347 3.58241 8.14347 3.10753 7.85057 2.81464C7.55768 2.52175 7.08281 2.52175 6.78991 2.81464L0.719914 8.88464C0.579262 9.02529 0.500244 9.21606 0.500244 9.41497C0.500244 9.61388 0.579262 9.80465 0.719914 9.9453L6.78991 16.0153C7.08281 16.3082 7.55768 16.3082 7.85057 16.0153C8.14347 15.7224 8.14347 15.2475 7.85057 14.9546L3.06091 10.165L18.2499 10.165C18.6641 10.165 18.9999 9.82919 18.9999 9.41498C18.9999 9.00076 18.6641 8.66498 18.2499 8.66498L3.0609 8.66498L7.85057 3.8753Z"
        fill="#292D32"
      />
      <path
        d="M21.75 1L21.75 17.83"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <g clipPath="url(#clip0_1_1407)">
        <path
          d="M5 6.75L9.5 11.25L14 6.75"
          stroke="#64748B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1407">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Star = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.3896 1.24486C8.73698 0.827534 9.37777 0.827534 9.72515 1.24486L12.6241 4.72753C12.7166 4.83864 12.8354 4.92492 12.9696 4.97854L17.1777 6.65942C17.6819 6.86084 17.8799 7.47027 17.5904 7.92961L15.174 11.7629C15.0969 11.8852 15.0515 12.0248 15.042 12.169L14.7438 16.6906C14.708 17.2324 14.1896 17.609 13.6633 17.4756L9.2709 16.362C9.13077 16.3265 8.98399 16.3265 8.84386 16.362L4.45148 17.4756C3.92514 17.609 3.40673 17.2324 3.37099 16.6906L3.07274 12.169C3.06322 12.0248 3.01786 11.8852 2.94077 11.7629L0.524386 7.92961C0.234832 7.47027 0.432847 6.86084 0.937096 6.65942L5.14515 4.97854C5.2794 4.92492 5.39814 4.83864 5.49063 4.72753L8.3896 1.24486Z"
        fill={color}
      />
    </svg>
  );
};

export const LeftArrowWithBg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
    >
      <circle cx="23" cy="23" r="23" fill="#F1F5F9" />
      <path
        d="M24.5 18L19.5 23L24.5 28"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RightArrowWithBg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
    >
      <circle
        cx="23"
        cy="23"
        r="23"
        transform="matrix(-1 0 0 1 46 0)"
        fill="#F1F5F9"
      />
      <path
        d="M21.5 18L26.5 23L21.5 28"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MenuIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1_2158)">
        <path
          d="M4 6H20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 12H20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 18H20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2158">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1_876)">
        <path
          d="M2.91626 7H11.0829"
          stroke="#64748B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_876">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PLusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1_882)">
        <path
          d="M7 2.91675V11.0834"
          stroke="#64748B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.91626 7H11.0829"
          stroke="#64748B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_882">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1_904)">
        <path
          d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
          stroke="#EF4444"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 7.5H10.0083"
          stroke="#EF4444"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16797 10H10.0013V13.3333H10.8346"
          stroke="#EF4444"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_904">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RightMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_1_919)">
        <rect width="18" height="18" rx="9" fill="#E9EFFD" />
        <path
          d="M6 9L8.33333 11L13 7"
          stroke="#2563EB"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_919">
          <rect width="18" height="18" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6 1.5V3.75"
        stroke="#64748B"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.5V3.75"
        stroke="#64748B"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.625 6.81738H15.375"
        stroke="#64748B"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z"
        stroke="#64748B"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.771 10.2749H11.7778"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.771 12.5249H11.7778"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99661 10.2749H9.00335"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99661 12.5249H9.00335"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.22073 10.2749H6.22747"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.22073 12.5249H6.22747"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
