import React, {useEffect, useState} from "react";
import {Filter, FilterSearch} from "../../../types/types";
import {CloseSideBarArrow, DownArrow, FilterIcon, Star} from "../../../Icons";
import {Accordion} from "react-bootstrap";
import Slider from "rc-slider";
import "./Filter.scss";
import "rc-slider/assets/index.css";
import {FilterType} from "../../../types/filter/FilterType";
import {filterService} from "../../../services/FilterService";
import {useDispatch, useSelector} from "react-redux";
import {setActiveFilters} from "../../../actions";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {FilterCategory} from "../../../types/filter/FilterCategory";
import DurationUtil from "../../../utils/DurationUtil";
import {useSearchParams} from "react-router-dom";
import UrlUtil from "../../../utils/UrlUtil";
import FilterUtil from "../../../utils/FilterUtil";

interface FilterProps {
  handleClose?: () => void;
  show?: boolean;
  filterVacations: (activeFilters: FilterSearch[]) => void;
}
const FilterBar: React.FC<FilterProps> = ({ handleClose, show, filterVacations}) => {

  const [filters, setFilters] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(3000);

  const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=> {
    filterService.getFilters(selectedFilters, searchParams.get("filter") ?? "").then(filters => {
      setFilters(filters);
    })
  }, [searchParams, selectedFilters]);

    useEffect(() => {
        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const activeFilters: FilterSearch[] = [...selectedFilters, ...urlFilters];

        const priceFilter = activeFilters.find(filter => filter.filterType === "price");
        if (priceFilter) {
            const [min, max] = priceFilter.value.split("-");
            setMinPrice(parseInt(min));
            setMaxPrice(parseInt(max));
        }
    }, [searchParams, selectedFilters]);

  const handleFilterType = (filter: Filter, key: number) => {
    switch (FilterType[filter.inputType?.toUpperCase()]) {
      case FilterType.DROPDOWN:
      case FilterType.CHECKBOX:
          if (filter.filterType === "stars") {
              return renderRating(filter, key);
          }
        return renderDropdown(filter, key);
      default:
        return null;
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, filterItem: { displayValue: string; value: string; count: number; }, filter: Filter) => {
    const index = selectedFilters.findIndex(selectedFilter => selectedFilter.value === filterItem.value);
    let activeFilters: FilterSearch[] = [...selectedFilters];
    let urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
    if (e.target.checked && index === -1) {
      activeFilters = [...selectedFilters, {
        filterCategory: filter.filterCategory,
        filterType: filter.filterType,
        value: filterItem.value,
        displayValue: filterItem.displayValue
      }];
    } else {
      activeFilters.splice(index, 1);
      const urlIndex = urlFilters.findIndex(selectedFilter => selectedFilter.value === filterItem.value);
      if (urlIndex > -1) {
        urlFilters.splice(urlIndex, 1);
      }
    }

    filterService.getFilters(activeFilters, searchParams.get("filter") ?? "").then(filters => {
      setFilters(filters);
    });
    setSearchParams(params => {
      params.set("page", "1");
      params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters, ...urlFilters])));
      return params;
    });

    filterVacations(activeFilters);
    dispatch(setActiveFilters([...activeFilters]))
  }

  const checkIfSelected = (item: {displayValue: string, value: string, count: number}) => {
      const selectedFilter = selectedFilters.findIndex(filter => filter.value === item.value) > -1;
      const isFoundInUrl = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter")).findIndex(filter => filter.value === item.value) > -1;

      return selectedFilter || isFoundInUrl;
  }

  const renderValue = (filter: Filter, value: { displayValue: string; value: string; region?: string; count: number }) => {
      switch (filter.filterType.toUpperCase()) {
          case FilterCategory.DURATION:
              return DurationUtil.renderDurationDisplayValue(value.displayValue);
          case FilterCategory.DEPARTURE_AIRPORT:
              return `Vanaf ${value.displayValue}`;
          default:
              return value.displayValue;
      }
  }

  const renderDropdown = (filter: Filter, key: number) => {
    return (
        <div className="vervoer" key={key}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <h5 className="w-100 m-0">
                  { filter.name }
                  <div className="dropdown-icon">
                    <DownArrow/>
                  </div>
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                <div className="check-box-wrapper">
                  {filter.values?.map((it, i) => (
                      <div key={i} className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <input
                              onChange={(e) => handleChange(e, it, filter)}
                              type="checkbox"
                              checked={checkIfSelected(it)}
                              name={it.value}
                              id={it.value}
                          />
                          <label htmlFor={it.value}>
                            <h6>
                                {renderValue(filter, it)}
                            </h6>
                          </label>
                        </div>
                        <h6>{ it.count ?? "" }</h6>
                      </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
    )
  }

  const renderRating = (filter: Filter, key: number) => {
    return (
        <div key={key} className="rating">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <h5 className="w-100 m-0">
                  Aantal Sterren
                  <div className="dropdown-icon">
                    <DownArrow/>
                  </div>
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                <div className="check-box-wrapper">
                  {filter.values?.map((it, i) => renderRatingItem(it, i, filter))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
    )
  }

  const renderRatingItem = (item: {displayValue: string, value: string, region?: string, count: number}, key: number, filter: Filter) => {
    return (
        <div key={key} className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-2">
                <input
                    type="checkbox"
                    checked={checkIfSelected(item)}
                    name={item.value}
                    onChange={(e) => handleChange(e, item, filter)}
                    id={item.value}
                />
                <label htmlFor={item.value}>
                    {renderStarIcons(parseInt(item.value))}
                </label>
            </div>
            <h6>
                {item.count ?? ""}
            </h6>
        </div>
    )
  }

    const renderStarIcons = (value: number) => {
        return (
            <div className="d-flex align-items-center gap-1">
                <Star color="#FB923C"/>
                <Star color={value < 2 ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 3 ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 4 ? "#CBD5E1" : "#FB923C"}/>
                <Star color={value < 5 ? "#CBD5E1" : "#FB923C"}/>
            </div>
        );
    }

    const handlePriceRange = (value: {min: number, max: number}) => {
      const activeFilters: FilterSearch[] = [...selectedFilters];
      const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
      const filter: FilterSearch = {
          filterCategory: "vacation",
          filterType: "price",
          value: `${value.min}-${value.max}`,
      }

      const index = [...activeFilters,  ...urlFilters].findIndex(filter => filter.filterType === "price");
      const urlIndex = urlFilters.findIndex(filter => filter.filterType === "price");
      const activeIndex = activeFilters.findIndex(filter => filter.filterType === "price");
      if (index > -1) {
          activeFilters.splice(activeIndex, 1, filter);
          urlFilters.splice(urlIndex, 1, filter);
      } else {
        activeFilters.push(filter);
      }
      setSearchParams(params => {
          params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters, ...urlFilters])));
          return params;
      });

      dispatch(setActiveFilters([...activeFilters]));
    }
    const renderPriceRange = (min: number, max: number, currentMin: number, currentMax: number) => {
      return (
          <div className="vervoer">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {" "}
                    <h5 className="w-100 m-0">
                      Prijs
                      <div className="dropdown-icon">
                        <DownArrow/>
                      </div>
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="price-range-slider">
                      <div className="range-slider-wrapper">
                        <Slider
                            min={min}
                            max={max}
                            value={[currentMin, currentMax]}
                            defaultValue={[min, max]}
                            onChangeComplete={(newValue => handlePriceRange({min: newValue[0], max: newValue[1]}))}
                            onChange={(newValue => {
                                setMinPrice(newValue[0]);
                                setMaxPrice(newValue[1]);
                            })}
                            step={100}
                            range
                        />
                      </div>

                      <div className="mini_max_price_box">
                        <div className="price_item">
                          <h6>Mini. prijs</h6>
                          <div className="input_number">
                            &euro;
                            <input
                                type="number"
                                className={"min-price-desktop"}
                                value={currentMin}
                                onChange={(e) => {
                                    if (parseInt(e.target.value) < min) {
                                        setMinPrice(min)
                                        handlePriceRange({min: min, max: currentMax})
                                    } else {
                                        setMinPrice(parseInt(e.target.value))
                                        handlePriceRange({min: parseInt(e.target.value), max: currentMax})
                                    }
                                }}
                                max={max}
                                min={min}
                            />
                            <input
                                type="number"
                                className={"min-price-mobile"}
                                value={currentMin}
                                onBlur={(e) => {
                                    if (parseInt(e.target.value) < min) {
                                        setMinPrice(min)
                                        handlePriceRange({min: min, max: currentMax})
                                    } else {
                                        setMinPrice(parseInt(e.target.value))
                                        handlePriceRange({min: parseInt(e.target.value), max: currentMax})
                                    }
                                }}
                                max={max}
                                min={min}
                            />
                          </div>
                        </div>
                        <div className="price_item">
                          <h6>Max. prijs</h6>
                          <div className="input_number">
                            &euro;
                            <input
                                className={"max-price-desktop"}
                                type="number"
                                value={currentMax}
                                onChange={(e) => {
                                    if (parseInt(e.target.value) > max) {
                                        setMaxPrice(max)
                                        handlePriceRange({min: currentMin, max: max})
                                    } else {
                                        setMaxPrice(parseInt(e.target.value))
                                        handlePriceRange({min: currentMin, max: parseInt(e.target.value)})
                                    }
                                }}
                                max={max}
                                min={min}
                            />
                            <input
                                className={"max-price-mobile"}
                                type="number"
                                value={currentMax}
                                onBlur={(e) => {
                                    if (parseInt(e.target.value) > max) {
                                        setMaxPrice(max)
                                        handlePriceRange({min: currentMin, max: max})
                                    } else {
                                        setMaxPrice(parseInt(e.target.value))
                                        handlePriceRange({min: currentMin, max: parseInt(e.target.value)})
                                    }
                                }}
                                onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                                max={max}
                                min={min}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
        )
  }


  return (
      // @ts-ignore
      <div className="filter-box">
        <div className="top-section">
          <div className="d-flex align-items-center gap-2">
            <FilterIcon/>
            <h4>Filters</h4>
          </div>
          {handleClose ? (
              <button type="button" onClick={() => handleClose?.()}>
                <CloseSideBarArrow/>
              </button>
          ) : (
              ""
          )}
        </div>

        {renderPriceRange(0, 3000, minPrice, maxPrice)}
        {
          filters.map((filter, key) => {
            return handleFilterType(filter, key)
          })}
      </div>
  );
}

export default FilterBar;