import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { MenuIcon } from "../../../Icons";

interface HeaderProps {
  styleFor?: string;
}

const Header: React.FC<HeaderProps> = ({ styleFor }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navItems = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About Us",
      link: "/",
    },
    {
      title: "FAQ’s",
      link: "/",
    },
    {
      title: "Testimonials",
      link: "/",
    },
    {
      title: "Blogs",
      link: "/",
    },
  ];

  const [activeMenu, setActiveMenu] = useState("Home");
  return (
    <section id="header">
      {" "}
      <div
        className={`header-contents ${
          styleFor === "search" ? "search-style" : ""
        }`}
      >
        <Link to="/" className="logo">
          <img src="/assets/img/logo.png" alt="" />
        </Link>

        <nav>
          <ul>
            {navItems.map((it, i) => (
              <li key={i}>
                <Link
                  className={`${activeMenu === it.title ? "active-menu" : ""} `}
                  to={it.link}
                  onClick={() => setActiveMenu(it.title)}
                >
                  {it.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="right-side" />
        <button className="menu-btn" onClick={handleShow}>
          <MenuIcon color={`${styleFor !== "search" ? "#fff" : "#1e1e1e"}`} />
        </button>
      </div>
      <>
        <Offcanvas className="menu-offcanvas" show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <nav>
              <ul>
                {navItems.map((it, i) => (
                  <li key={i} onClick={() => setShow(false)}>
                    <Link
                      className={`${
                        activeMenu === it.title ? "active-menu" : ""
                      } `}
                      to={it.link}
                      onClick={() => setActiveMenu(it.title)}
                    >
                      {it.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </section>
  );
};

export default Header;
