import {HttpMethod} from "../types/api/HttpMethod";
import {ApiResponse} from "../types/api/ApiResponse";
import ConfigUtil from '../utils/ConfigUtil';
import PluginOptions from "../types/PluginOptions";

class ApiService {
    static liveApiUrl: string = "https://api.vergelijkjevakantie.app";
    private pluginOptions: PluginOptions;

    public constructor() {
        this.pluginOptions = ConfigUtil.getConfig();
    }

    public getApiUrl(): string {
        if(this.pluginOptions.apiBaseUrl === undefined || this.pluginOptions.apiBaseUrl === '' || this.pluginOptions.apiBaseUrl === null) {
            this.pluginOptions.apiBaseUrl = ApiService.liveApiUrl;
        }

        return `${this.pluginOptions.apiBaseUrl}/api/v1/`;
    }

    public call<T>(path: string, method: HttpMethod = HttpMethod.GET, body: string | null = null, formData: FormData | null = null): Promise<ApiResponse<T>> {
        return new Promise((resolve, reject) => {
            const requestMethod: string = HttpMethod[method];
            const requestOptions: RequestInit = {
                method: requestMethod,
                mode: "cors",
                headers: {}
            };

            if (body) {
                requestOptions.body = body;
            } else if (formData) {
                requestOptions.body = formData;
            }

            fetch(`${this.getApiUrl()}${path}`, requestOptions)
                .then(response => {
                    const jsonResponse = response.json();

                    if (!jsonResponse) {
                        reject(new Error('Could not parse the api call response'));
                    }

                    resolve(jsonResponse);
                }).then(reject);
        });
    }
}

export const apiService: ApiService = new ApiService();
