import React from "react";
import "./ReadyFor.scss";

export default function ReadyFor() {
  return (
    <section id="ready-for">
      <div className="container">
        <h1 className="section-title">
          Ready for your next journey?{" "}
          <img src="/assets/img/rainbow.png" alt="" />{" "}
        </h1>
        <p>
          Discover most affordable bus and travel options to popular
          destinations below.
        </p>

        <div className="btn-group">
          {[
            "Amsterdam",
            "Keukenhof Gardens",
            "Kinderdijk Windmills",
            "Giethoorn",
            "Maastricht",
            "Delft",
            "Zaanse Schans",
            "The Hague",
            "Maastricht",
            "Amsterdam",
            "Delft",
            "Keukenhof Gardens",
            "Zaanse Schans",
            "Kinderdijk Windmills",
            "The Hague",
            "Giethoorn",
            "Maastricht",
            "Kinderdijk Windmills",
            "Amsterdam",
            "The Hague",
            "Keukenhof Gardens",
            "Maastricht",
            "Kinderdijk Windmills",
            "Delft",
            "Amsterdam",
            "Keukenhof Gardens",
            "Giethoorn",
          ].map((it, i) => (
            <button key={i} className="outline-btn">
              {it}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}
