import React from "react";
import "./HaveYou.scss";

const HaveYou: React.FC = () => {
  return (
    <section id="have-you">
      <div className="container">
        <h1 className="section-title">
          Have you not yet been able to make a choice?{" "}
        </h1>
        <p>Be inspired and sign up for the TUI newsletter.</p>

        <h5>also look at</h5>

        <div className="btn-group">
          {[
            "Vakantie Griekenland",
            "All inclusive Griekenland",
            "Last minute Griekenland",
            "Appartement Griekenland",
            "Kindervakantie Griekenland",
            "Kindervakantie Griekenland",
            "Luxe Vakantie Griekenland",
            "Adults-only Griekenland",
            "Rondreis Griekenland",
            "Stedentrip Athene",
            "Ely-onye-gneKenland",
            "Stedentrip Griekenland",
            "Vakantie Athene",
            "Last minute Athene",
            "Cruise Griekenlan<",
            "Vliegtickets Griekenland",
          ].map((it, i) => (
            <button key={i} className="outline-btn">
              {it}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HaveYou;
