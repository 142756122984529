import React, { useEffect, useRef, useState } from "react";
import "./AddGuest.scss";
import {
  DownArrow,
  InfoIcon,
  MinusIcon,
  PLusIcon,
  RightMark,
} from "../../../Icons";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {
  addParticipantAdult,
  addParticipantChild,
  removeParticipantAdult,
  removeParticipantChild,
} from "../../../actions";

const AddGuest: React.FC = () => {
  const [isOpenAddGuestDropDown, setIsopenAddGuestDropdown] = useState(false);
  const [ageDropdown, setAgeDropdown] = useState(false);
  const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
  const participantAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
  const dispatch = useDispatch();
  //handle outside click
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const onOutsideClick = () => {
    setIsopenAddGuestDropdown(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutsideClick]);
  //

  const selectAgeRef = useRef<HTMLDivElement | null>(null);
  const onOutClickAge = () => {
    setAgeDropdown(false);
    setAgeDropdown(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectAgeRef.current &&
        !selectAgeRef.current.contains(event.target as Node)
      ) {
        onOutClickAge();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutClickAge]);

  return (
    <div>
      {" "}
      <div className=" add-guest-dropdown" ref={wrapperRef}>
        <h5 onClick={() => setIsopenAddGuestDropdown(!isOpenAddGuestDropDown)}>
          {participantAmountAdults || participantAmountChildren
            ? `${
                  participantAmountAdults > 0 ? participantAmountAdults + " Volwassenen" : ""
              } ${
                participantAmountAdults > 0 && participantAmountChildren > 0 ? "," : ""
              } ${
                  participantAmountChildren > 0
                  ? participantAmountChildren + " Kinderen"
                  : ""
              }`
            : " Selecteer Reisgezelschap"}
        </h5>

        {isOpenAddGuestDropDown && (
          <div className="guest-add-dropdown-contents">
            <div className="select-contents">
              <h4>Uw reisgezelschap</h4>
              <h6 className="mt-2">
                Het reisgezelschap bestaat uit volwassenen en kinderen, met een maximaal van 8 personen.
              </h6>

              <div className="companion-age-wrapper">
                <div className="companion-age">
                  <div>
                    <h5>Volwassenen</h5>
                    <h6>18+</h6>
                  </div>
                  <div className="input-number-box">
                    <button disabled={participantAmountAdults + participantAmountChildren === 1} onClick={() => {
                      dispatch(removeParticipantAdult())
                    }}>
                      <MinusIcon />
                    </button>

                    <span>{participantAmountAdults}</span>
                    <button
                      disabled={participantAmountAdults + participantAmountChildren === 8}
                      onClick={() => {
                        dispatch(addParticipantAdult())
                      }}
                    >
                      <PLusIcon />
                    </button>
                  </div>
                </div>
                <div className="companion-age">
                  <div>
                    <h5>Kinderen</h5>
                    <h6>0-17 Jaar</h6>
                  </div>

                  <div className="input-number-box">
                    <button disabled={participantAmountAdults + participantAmountChildren === 1} onClick={() => {

                      dispatch(removeParticipantChild())
                    }}>
                      <MinusIcon />
                    </button>

                    <span>{participantAmountChildren}</span>
                    <button
                      disabled={participantAmountAdults + participantAmountChildren === 8}
                      onClick={() =>
                        dispatch(addParticipantChild())
                      }
                    >
                      <PLusIcon />
                    </button>
                  </div>
                </div>
              </div>

                <button
                    className="save-btn"
                    onClick={() => setIsopenAddGuestDropdown(false)}
                >
                  Opslaan
                </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddGuest;
